<template>

  <div>
    <chatter-list-add-new
        :is-add-new-chatter-sidebar-active.sync="isAddNewChatterSidebarActive"
        :chatter-id="chatterID"
        @refetch-data="getForums"
    />
    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Показ</label>
            <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>строка</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Поиск..."
                  @input="getForums"
              />
              <b-button
                  variant="primary"
                  @click="addChatter(0)"
              >
                <span class="text-nowrap">Добавить</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refChatterListTable"
          class="position-relative d-none"
          :items="fetchChatters"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Записи не найдены"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Chatter -->
        <template #cell(title)="data">
          <b-media vertical-align="center">
            <span
                class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.title }}
            </span>
          </b-media>
        </template>

        <!-- Column: Просмотр -->
        <template #cell(views)="data">
            <span class="align-text-top">{{ data.item.views }}</span>
        </template>

        <!-- Column: Ответ -->
        <template #cell(answered)="data">
            <span class="align-text-top">{{ data.item.answered }}</span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <span @click="chatterPosts(data.item.id)">
            <feather-icon icon="MessageCircleIcon" class="text-success mr-1 font-medium-5"/>
          </span>
          <span @click="addChatter(data.item.id)">
            <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
          </span>
          <span @click="deleteChatter(data.item.id)">
            <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
          </span>
        </template>

      </b-table>
      <div class="mx-2 mb-2 d-none">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalChatters"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-card
        no-body
        class="card-employee-task"
    >

      <!-- body -->
      <b-card-body>
        <div
            v-for="(forum,index) in forums"
            :key="index"
            class="employee-task d-flex justify-content-between align-items-center forum-hover"
        >
          <b-media no-body @click="chatterPosts(forum.id)">
            <b-media-aside class="mr-75">
              <b-avatar
                  rounded
                  size="80"
                  :src="forum.user && forum.user.photo ? `https://edu.startupchoikhona.tj/backend/${forum.user.photo}` : require('@/assets/images/user/noPhoto.jpeg')"
              />
            </b-media-aside>
            <b-media-body class="my-auto" @click="chatterPosts(forum.id)">
              <h4 class="mb-0 title bold">
                {{ forum.title }}
              </h4>
              <p class="mb-0"><small class="badge badge-info">{{ forum.chatter_category.name }}</small></p>
              <p class="mb-0"><small class="">Автор: {{ forum.user ? forum.user.name : '' }}</small></p>
            </b-media-body>
          </b-media>
          <div class="d-flex align-items-center">
            <small class="text-muted mr-75">{{ forum.answered }}</small>
            <span @click="chatterPosts(forum.id)">
              <feather-icon icon="MessageCircleIcon" class="text-success mr-1 font-medium-5"/>
            </span>
            <span @click="addChatter(forum.id)" v-if="userDataStorage.roles[0] === 'admin' || userDataStorage.email === forum.user.email">
              <feather-icon icon="EditIcon" class="text-primary mr-1 font-medium-5"/>
            </span>
            <span @click="deleteChatter2(forum.id)" v-if="userDataStorage.roles[0] === 'admin' || userDataStorage.email === forum.user.email">
              <feather-icon icon="Trash2Icon" class="text-danger mr-1 font-medium-5"/>
            </span>
          </div>
        </div>
      </b-card-body>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Показано {{ dataMeta.from }} до {{ dataMeta.to }} из {{ dataMeta.of }} строк</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="currentPage"
                :total-rows="totalChatters"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BCardTitle, BCardHeader, BCardBody, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import useChattersList from './useChattersList'
import ChatterListAddNew from './ChatterListAddNew.vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { useToast } from 'vue-toastification/composition'
/* eslint-disable global-require */
const $trackBgColor = '#e9ecef'

export default {
  components: {
    ChatterListAddNew,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMediaAside,
    BMediaBody,

    vSelect,
    VueApexCharts,
  },
  data() {
    return {
      userDataStorage: JSON.parse(localStorage.getItem('userData')),
      chartData: [],
      chartColor: [$themeColors.primary, $themeColors.danger, $themeColors.success, $themeColors.secondary, $themeColors.warning, $themeColors.primary],
      chartSeries: [45, 65, 60, 35, 65, 80],
      employeeData: [
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'),
          userFullName: 'Ryan Harrington',
          designation: 'iOS Developer',
          duration: '9hr 20m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Louisa Norton',
          designation: 'UI Designer',
          duration: '4hr 17m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-1.jpg'),
          userFullName: 'Jayden Duncan',
          designation: 'Java Developer',
          duration: '12hr 8m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
          userFullName: 'Cynthia Howell',
          designation: 'Anguler Developer',
          duration: '3hr 19m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-16.jpg'),
          userFullName: 'Helena Payne',
          designation: 'Marketing',
          duration: '9hr 50m',
        },
        {
          avatar: require('@/assets/images/portrait/small/avatar-s-13.jpg'),
          userFullName: 'Troy Jensen',
          designation: 'iOS Developer',
          duration: '4hr 48m',
        },
      ],
      chart: {
        series: [65],
        options: {
          grid: {
            show: false,
            padding: {
              left: -15,
              right: -15,
              top: -12,
              bottom: -15,
            },
          },
          colors: [$themeColors.primary],
          plotOptions: {
            radialBar: {
              hollow: {
                size: '22%',
              },
              track: {
                background: $trackBgColor,
              },
              dataLabels: {
                showOn: 'always',
                name: {
                  show: false,
                },
                value: {
                  show: false,
                },
              },
            },
          },
          stroke: {
            lineCap: 'round',
          },
        },
      },
      forums: [],
    }
  },
  methods: {
    addChatter(idChatter) {
      this.chatterID = idChatter
      this.isAddNewChatterSidebarActive = true
    },
    chatterPosts(idChatter) {
      this.$router.replace({ name: 'student-forum-post', params: { id: idChatter }, })
    },
    deleteChatter2(idChatter) {
      Swal.fire({
        title: 'Вы действительно хотите удалить этот форум?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Отмена',
        confirmButtonText: 'Удалить',
        customClass: {
          cancelButton: 'btn btn-outline-danger ml-1',
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
          .then(result => {
            if (result.value && idChatter > 0) {
              this.$http
                  .post(`https://edu.startupchoikhona.tj/backend/api/student/chatter-discussion/delete/${idChatter}`, {})
                  .then(response => {
                    this.getForums()
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Успешно удалено.',
                        icon: 'AlertTriangleIcon',
                        variant: 'success',
                      },
                    })
                  })
                  .catch(() => {
                    this.toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Ошибка при удалении форума',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                      },
                    })
                  })
            } else if (result.dismiss === 'cancel') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Удаление форум отменено',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    getForums() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/student/chatter-discussion/index', {
        search: this.searchQuery,
        length: this.perPage,
        page: this.currentPage,
      })
      .then(response => {
        this.forums = response.data.data.items
      })
    },
  },
  setup() {
    const isAddNewChatterSidebarActive = ref(false)
    const chatterID = ref(0)
    const {
      fetchChatters,
      deleteChatter,
      tableColumns,
      perPage,
      currentPage,
      totalChatters,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChatterListTable,
      refetchData,

    } = useChattersList()

    return {
      fetchChatters,
      deleteChatter,
      tableColumns,
      perPage,
      currentPage,
      totalChatters,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refChatterListTable,
      refetchData,

      // Filter
      avatarText,

      isAddNewChatterSidebarActive,
      chatterID,
    }
  },
  created() {
    for (let i = 0; i < this.employeeData.length; i += 1) {
      const chartClone = JSON.parse(JSON.stringify(this.chart))
      chartClone.options.colors[0] = this.chartColor[i]
      chartClone.series[0] = this.chartSeries[i]
      this.chartData.push(chartClone)
    }
    this.getForums()
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.forum-hover:hover {
  cursor: pointer;
  background: #f8f8f8;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
